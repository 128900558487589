.timeline ul {
  list-style-type: none;
  position: relative;
}

.timeline ul:before {
  content: ' ';
  background: #687887;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

.timeline ul > li {
  margin: 20px 0;
  padding-left: 20px;
}

.timeline ul > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #2E2C70;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.heading {
  text-align: center;
  font-size: 0.8em;
}

.center-align {
  display: table;
}

.timeline input[type="radio"] {
  opacity: 0;
}

.timeline label {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: #140858;
  color: white;
  padding: 7px 14px;
  border-radius: 5px;
  display: inline-block;
}

.timeline input[type="radio"]:checked + label {
  background-color: #35826A;
}
.timeline input[type="radio"]:focus + label {
  box-shadow:inset 0px 0px 0px 4px #35826A;
}
.timeline .not-available {
  background-color: white;
  color: #687887;
  width: 66px;
  margin-left: 8px;
  border: none;
  cursor: auto;
}

.timeline fieldset {
  border: 0;
}

.timeline .loading {
  opacity: 0.05;
  pointer-events: none;
}

.timeline {
  position: relative;
}

.change-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
}

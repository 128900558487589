label, legend {
  font-size: 15px;
  font-weight: bold;
}

.inline-error {
  border-left: 5px solid #DC143C;
  padding-left: 15px;
  color: #DC143C;
  font-weight: bold;
  font-size: 16px;
}

.inline-warning {
  border-left: 5px solid #C75000;
  padding-left: 15px;
  color: #C75000;
  font-weight: bold;
  font-size: 16px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.nextButton {
  margin-top: 30px;
}
